a, abbr, acronym, address, applet, big, blockquote, body, caption, cite, code, dd, del, dfn, div, dl, dt, em, fieldset, form, h1, h2, h3, h4, h5, h6, html, iframe, img, ins, kbd, label, legend, li, object, ol, p, pre, q, s, samp, small, span, strike, strong, sub, sup, table, tbody, td, tfoot, th, thead, tr, tt, ul, var {
  box-sizing: border-box;
}

a {
  cursor: pointer;
  text-decoration: none;
}

a, abbr, acronym, address, applet, big, blockquote, body {
  vertical-align: baseline;
}

a, abbr, acronym, address, applet, big, blockquote, body, caption {
  font-weight: inherit;
  font-style: inherit;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: 100%;
}

cite, code, dd, del, dfn, div, dl, dt, em, fieldset, form, h1, h2, h3, h4, h5, h6, html, iframe, img, ins, kbd, label, legend, li, object, ol, p, pre, q, s, samp, small, span, strike, strong, sub, sup {
  font-weight: inherit;
  font-style: inherit;
  vertical-align: baseline;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: 100%;
}

table {
  font-weight: inherit;
  font-style: inherit;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: 100%;
}

tbody {
  vertical-align: baseline;
}

tbody, td {
  font-weight: inherit;
  font-style: inherit;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: 100%;
}

tfoot {
  vertical-align: baseline;
}

tfoot, th, thead, tr, tt, ul, var {
  font-weight: inherit;
  font-style: inherit;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: 100%;
}

thead, tr, tt, ul, var {
  vertical-align: baseline;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

table {
  border-collapse: separate;
  border-spacing: 0;
  vertical-align: middle;
}

caption, td, th {
  text-align: left;
  vertical-align: middle;
  font-weight: 400;
}

a img {
  border: none;
}

a {
  color: #222;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

audio, canvas, video {
  display: inline-block;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  font-weight: inherit;
  font-style: inherit;
  vertical-align: baseline;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: 100%;
  display: block;
}

[hidden], audio:not([controls]) {
  display: none;
}

* {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
}

a, a:hover, a:active, a:visited, a:link, a:focus {
  -webkit-tap-highlight-color: #0000;
  -webkit-tap-highlight-color: transparent;
  background: none;
  outline: none;
  text-decoration: none;
}

.mgtp15 {
  margin-top: .3rem !important;
}

body {
  background-color: #fff;
  width: 7.5rem;
  margin: 0 auto;
  font-family: Noto Sans SC;
}

.slide-header {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 1.08rem;
  padding: 0 .3rem;
  display: flex;
}

.slide-header > img:first-child {
  width: .4rem;
  height: .4rem;
}

.slide-header > .btn-close {
  width: .34rem;
  height: .34rem;
}

#sidebar {
  background-color: #fff;
  width: 200px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: -1000px;
  overflow: hidden;
}

.type-list {
  width: 100%;
  padding: 0 .3rem;
}

.header-top {
  width: 100%;
  margin: 0 auto .2rem;
  position: relative;
}

.header-top > .btn-history {
  width: .56rem;
  height: .56rem;
  display: flex;
  position: absolute;
  top: .3rem;
  right: .3rem;
}

.header-top > .btn-history > img {
  width: 100%;
  height: 100%;
}

.header-top > a {
  width: 100%;
  height: 1rem;
  display: block;
}

.head-img {
  width: 100%;
  height: 1rem;
}

.form-search {
  width: 6.94rem;
  height: .7rem;
  margin: 0 auto;
  position: relative;
}

.form-search > .ipt-search {
  background-color: #e9f6fd;
  border: .04rem solid #292f52;
  border-radius: .2rem;
  width: 100%;
  height: .7rem;
  padding: 0 .4rem 0 .2rem;
  position: absolute;
}

.form-search .btn-search {
  justify-content: center;
  align-items: center;
  width: .28rem;
  height: .28rem;
  display: flex;
  position: absolute;
  top: .24rem;
  right: .24rem;
}

.form-search .icon-search {
  background: url("search.3adc6152.png") center / 100% no-repeat;
  width: .46rem;
  height: .46rem;
  display: block;
}

.title {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: .56rem;
  margin: 0 auto .3rem;
  display: flex;
  position: relative;
}

.title:before {
  content: "";
  background-color: #292f52;
  border-radius: .05rem;
  width: .1rem;
  height: .54rem;
  position: absolute;
  top: 0;
  left: 0;
}

.title .lf {
  flex-direction: row;
  align-items: center;
  margin-left: .2rem;
  display: flex;
}

.title .icon {
  width: .4rem;
  height: .4rem;
  margin-right: .2rem;
}

.title span {
  color: #292f52;
  font-size: .34rem;
}

.title a {
  align-items: center;
  height: .4rem;
  display: flex;
}

.title img {
  width: .28rem;
  height: .28rem;
}

.footer {
  box-shadow: none;
  background-color: #e9f6fd;
  border: .04rem solid #292f52;
  border-radius: .3rem;
  width: 7rem;
  margin: .3rem auto;
}

.footer a {
  margin: 0 auto;
  display: flex;
}

.ft-img {
  width: 6.94rem;
  height: 100%;
  margin: 0 auto;
}

.footer .ft-txt {
  color: #000;
  text-align: center;
  width: 100%;
  margin-top: 0;
  margin-bottom: .1rem;
  font-size: .28rem;
  text-decoration: none;
  display: block;
}

.ft-nav {
  background-color: #0000;
  border-radius: .2rem;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 6.94rem;
  height: 1.04rem;
  margin: 0 auto;
  display: flex;
}

.ft-nav a {
  color: #292f52;
  text-align: center;
  font-size: .24rem;
  font-weight: bold;
  text-decoration: none;
}

.ft-nav span {
  color: #fff;
  width: .1rem;
  height: .4rem;
  overflow: hidden;
}

.empty {
  width: 100%;
  height: .4rem;
}

.adv {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 6.94rem;
  margin: 0 auto;
  display: flex;
}

.adv p {
  text-align: center;
  width: 100%;
  margin: .3rem 0;
  font-size: .3rem;
}

.adv-float {
  z-index: 999;
  justify-content: center;
  width: 100%;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
}

/*# sourceMappingURL=search.a9d01712.css.map */
